import React from 'react';
import PropTypes from 'prop-types';
import { Visible, Hidden } from 'react-grid-system';

import Desktop from './Desktop';
import Mobile from './Mobile';

const TabBar = ({ index, data }) => (
  <div>
    {/* Desktop */}
    <Visible lg xl>
      <Desktop index={index} data={data} />
    </Visible>

    {/* Mobile */}
    <Hidden lg xl>
      <Mobile index={index} data={data} />
    </Hidden>
  </div>
);

TabBar.propTypes = {
  index: PropTypes.number.isRequired,
  data: PropTypes.array.isRequired,
};

export default TabBar;