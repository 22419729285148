import React from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import Select from 'react-select';

const customStyles = {
  control: (base, state) => ({
    ...base,
    borderWidth: 0,
    borderRadius: 0,
    backgroundColor: 'white',
    boxShadow: 'none',
    borderBottomWidth: 1,
    borderStyle: 'solid',
    borderColor: '#ccc !important',
  }),
  option: (base, state) => ({
    ...base,
    paddingLeft: '1rem',
    fontSize: '.9375rem',
    backgroundColor: 'white !important',
    color: 'black',
  }),
  valueContainer: (base, state) => ({
    ...base,
    paddingLeft: '1rem',
    fontSize: '.9375rem',
  }),
};

const _handleChange = (selectedStore) => {
  navigate(selectedStore.value);
};

const Mobile = ({ index, data: tabs }) => {
  const options = [];

  tabs.forEach((tab) => {
    const { name, link } = tab;
    options.push({
      label: name,
      value: link,
    });
  });
  
  return (
    <Select
      options={options}
      value={{
        label: tabs[index].name,
        value: tabs[index].link,
      }}
      onChange={_handleChange}
      styles={customStyles}
      isSearchable={false}
    />
  );
};

Mobile.propTypes = {
  index: PropTypes.number.isRequired,
  data: PropTypes.array.isRequired,
};

export default Mobile;