import React from 'react';

import NewList from 'components/News/New/List';
import PageTitleBar from 'components/misc/PageTitleBar';
import TabBar from 'components/misc/TabBar';

const tabBarMenus = [
  { name: '새메뉴', link: '/news/new/' },
  { name: '이벤트', link: '/news/event/' },
  { name: '공지사항', link: '/news/notice/' },
];

const NewListPage = () => (
  <div>
    <PageTitleBar title="What's New" backgroundImage={require('../../../assets/images/banner/news.jpg')} />
    <TabBar index={0} data={tabBarMenus} />
    <NewList />
  </div>
);

export default NewListPage;
