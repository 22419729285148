import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { Container, Row, Col } from 'react-grid-system';
import classNames from 'classnames/bind';

import styles from './Desktop.module.scss';

const cx = classNames.bind(styles);

const Desktop = ({ index, data }) => (
  <div className={cx('container')}>
    <Container>
      <Row>
        <Col xs={10} offset={{ xs: 1 }}>
          <div className={cx('tabs')}>
            {data.map((menu, i) => {
              const { name, link } = menu;

              return (
                <div key={i} className={cx('tabWrapper')}>
                  <Link to={link} className={cx(['tab', { active: index === i }])}>{name}</Link>
                </div>
              );
            })}
          </div>
        </Col>
      </Row>
    </Container>
  </div>
);

Desktop.propTypes = {
  index: PropTypes.number.isRequired,
  data: PropTypes.array.isRequired,
};

export default Desktop;